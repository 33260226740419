import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

import addressTabIcon from "../addressTabIcon.png";
import zohoMysqlIcon from "../logo_1.png";
import Overview from "../Components/Overview";
import DBConnection from "../Components/DBConnection";
import Settings from "../Components/Settings";
import DataBackup from "../Components/DataBackup";
import HistoricalData from "../Components/HistoricalData/HistoricalData";

const ZOHO = window.ZOHO;

export default function SettingsRoute() {
  const [authLoadingZoho, setAuthLoadingZoho] = useState(false);
  const [revokeLoadingZoho, setRevokeLoadingZoho] = useState(false);
  const [zohoAuth, setZohoAuth] = useState(false);
  const [connectionUrl, setConnectionUrl] = useState(null);
  const [disabledTabs, setDisabledTabs] = useState({
    dbTab: false,
    settingsTab: false,
    backupData: false,
    historical: false,
  });

  const [snackbarMsg, setSnackbarMsg] = useState("");

  const [snackBarForError, setSnackBarForError] = useState(false);

  const [tabNumber, setTabNumber] = useState(null);
  const [zohoLoaded, setZohoLoaded] = useState(false);

  const [loading, setLoading] = useState(true);

  const [apiKey, setApiKey] = useState();

  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState("");
  const [zuid, setZuid] = useState(null);

  const tabText = {
    weight: 500,
    size: "16px",
    lineHeight: "24px",
    letterSpacing: "1%",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.38)",
    // textTransform:"capitalize"
  };

  const handleAuthCheckZoho = async ({ headers }) => {
    // for zoho auth verify
    // setAuthLoadingZoho(true);

    const authCheck = await axios({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/verifyauth`,
      headers: headers,
    });

    if (authCheck?.data?.org?.length > 0) {
      // setAuthLoadingZoho(false);
      return true;
    } else {
      // setAuthLoadingZoho(false);
      return false;
    }
  };

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        const currentEnv = await ZOHO.CRM.CONFIG.GetCurrentEnvironment();
        const dataCenterMap = {
          US: "https://www.zohoapis.com",
          EU: "https://www.zohoapis.eu",
          AU: "https://www.zohoapis.com.au",
          IN: "https://www.zohoapis.in",
          China: "https://www.zohoapis.com.cn",
          JP: "https://www.zohoapis.jp",
        };
        let dataCenterUrl = dataCenterMap?.[currentEnv?.deployment] || "";
        if (process.env.REACT_APP_SANDBOX === "true") {
          dataCenterUrl = "https://plugin-easymysqlsync.zohosandbox.com";
        }

        setDataCenterUrl(dataCenterUrl);

        const dataCenterData = {
          apiname:
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
          value: dataCenterUrl,
        };

        await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", dataCenterData);
        setZohoLoaded(true);
      });
      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  useEffect(() => {
    if (zohoLoaded) {
      // fetch previous saved data for external service and zoho crm
      fetchOrgVariablesData()
        .then(async ({ apiKey, orgid: orgId, connname, connectionUrl }) => {
          try {
            const testConnUrl = await axios.request({
              url: `${process.env.REACT_APP_DBURL}/verify`,
              method: "POST",
              headers: {
                orgid: orgId, // Org ID
                apikey: apiKey, // API KEy
                connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
              },
              data: {
                Connection_Url: connectionUrl,
              },
            });
            if (testConnUrl.data) {
              // setConnectionUrl(connectionUrl);
              setZohoAuth(true);
              setTabNumber(2);
            } else {
              setTabNumber(1);
            }
          } catch (error) {
            setTabNumber(1);
          }
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [zohoLoaded]);

  const dbConnectionCheck = async () => {
    try {
      const testConnUrl = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/verify`,
        method: "POST",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
        data: {
          Connection_Url: connectionUrl,
        },
      });
      if (testConnUrl.data) {
        // setConnectionUrl(connectionUrl);
        setTabNumber(2);
      } else {
        setTabNumber(1);
      }
    } catch (error) {
      setTabNumber(1);
    }
  };

  const handleAuthenticateZoho = async () => {
    setAuthLoadingZoho(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl:
          "https://accounts.zoho." +
          dataCenterUrl?.split("https://www.zohoapis.")?.[1],
        connName: process.env.REACT_APP_EXTENSION_IDENTIFIER + "__zoho",
        scope: [
          // "ZohoCRM.users.ALL",
          "ZohoCRM.users.CREATE",
          "ZohoCRM.users.READ",
          "ZohoCRM.users.UPDATE",
          "ZohoCRM.org.READ",
          "ZohoCRM.org.UPDATE",
          "ZohoCRM.settings.CREATE",
          "ZohoCRM.settings.READ",
          "ZohoCRM.settings.UPDATE",
          "ZohoCRM.coql.READ",
          "ZohoCRM.modules.CREATE",
          "ZohoCRM.modules.READ",
          "ZohoCRM.modules.UPDATE",
        ],
        redirectUrl: `${process.env.REACT_APP_ADMIN_SERVER_URL}/auth/zoho/callback`,
        crmApiDomain: dataCenterUrl,
        zuid: zuid,
      },
    });

    const myAuthCheckInterval = setInterval(async () => {
      const authCheckStatus = await handleAuthCheckZoho({
        headers: {
          apikey: apiKey,
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER + "__zoho",
          orgid: orgId,
          accountsurl: dataCenterUrl,
          dataCenterurlvariablename:
            process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
        },
        // dataCenterUrl: dataCenterUrl,
      });

      if (authCheckStatus) {
        // setTabNumber(1);
        setZohoAuth(true);
        setAuthLoadingZoho(false);
        clearInterval(myAuthCheckInterval);
        dbConnectionCheck();
      } else {
        // setAuthLoadingZoho(false);
      }
    }, 5000);

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const handleRevokeZoho = async () => {
    setRevokeLoadingZoho(true);

    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/auth/zoho/revoke",
      method: "GET",
      headers: {
        orgid: orgId,
        apikey: apiKey,
        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER + "__zoho",
      },
    });

    if (authUrlResp?.data) {
      setZohoAuth(false);
      setRevokeLoadingZoho(false);
    } else {
      setRevokeLoadingZoho(false);
    }
  };

  const fetchOrgVariablesData = async () => {
    /* 
      Get Organization Variables
    */

    const orgData = {
      apiKeys: [
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__API_KEY",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Organization_ID",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__ZAPI_Key",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__Data_Center_URL",
        process.env.REACT_APP_EXTENSION_IDENTIFIER + "__DB_Connection_URL",
      ],
    };

    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);

    setDataCenterUrl(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
      ]?.value
        ? orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
          ]?.value != "null"
          ? orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
            ]?.value
          : dataCenterUrl
        : dataCenterUrl
    );

    setApiKey(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
      ]?.value
    );
    setConnectionUrl(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__DB_Connection_URL`
      ]?.value
    );

    setOrgId(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
      ]?.value
    );

    setZapiKey(
      orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__ZAPI_Key`
      ]?.value
    );

    const headersZoho = {
      apikey:
        orgVariables?.Success?.Content?.[
          `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
        ]?.value,
      connname: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__zoho`,
      orgid:
        orgVariables?.Success?.Content?.[
          `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
        ]?.value,
      accountsurl: orgVariables?.Success?.Content
        ?.easygoogledriveforcrm__Data_Center_URL?.value
        ? orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
          ]?.value != "null"
          ? orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
            ]?.value
          : dataCenterUrl
        : dataCenterUrl,
      dataCenterurlvariablename: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`,
    };

    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();

    setZuid(orgResp?.org?.[0]?.primary_zuid);

    const authCheckStatusZoho = await handleAuthCheckZoho({
      headers: headersZoho,
      dataCenterUrl: orgVariables?.Success?.Content?.[
        `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
      ]?.value
        ? orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
          ]?.value != "null"
          ? orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
            ]?.value
          : dataCenterUrl
        : dataCenterUrl,
    });

    if (authCheckStatusZoho) {
      // Create Extension Widget
      const createExtension = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/extwidgets`,
        method: "POST",
        data: {
          widgetName: "MySQL Sync by Easy Pluginz",
          widgetUrl:
            " https://widgets.v1.mysqlsync.easy-pluginz.com/relatedlist",
          widgetDescription:
            "This widget you can utilize in any Standard Module and Custom Module to check history and restore data",
          widgetType: "RELATED_LIST",
          apiDomain: dataCenterUrl,
        },
        headers: {
          apikey:
            orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
            ]?.value,
          connname: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__zoho`,
          datacenterurl: orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
          ]?.value
            ? orgVariables?.Success?.Content?.[
                `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
              ]?.value != "null"
              ? orgVariables?.Success?.Content?.[
                  `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Data_Center_URL`
                ]?.value
              : dataCenterUrl
            : dataCenterUrl,
          orgid:
            orgVariables?.Success?.Content?.[
              `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
            ]?.value,
        },
      });

      //check db connection
      return {
        apiKey:
          orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__API_KEY`
          ]?.value,
        orgid:
          orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__Organization_ID`
          ]?.value,
        connname: `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__zoho`,
        connectionUrl:
          orgVariables?.Success?.Content?.[
            `${process.env.REACT_APP_EXTENSION_IDENTIFIER}__DB_Connection_URL`
          ]?.value,
      };
    } else {
      setTabNumber(0);
      setDisabledTabs({
        dbTab: true,
        settingsTab: true,
        backupData: true,
        historical: true,
      });
    }
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabNumber(newValue);
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        Loading
      </Backdrop>
    );
  }

  return (
    <>
      <Snackbar
        open={snackBarForError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }

          setSnackBarForError(false);
        }}
      >
        <MuiAlert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }

            setSnackBarForError(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
          {/* You might be entered a wrong API KEY. */}
        </MuiAlert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "36px",
          mb: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={zohoMysqlIcon} alt="Logo" />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          borderBottom: "1px solid rgba(232, 232, 232, 1)",
        }}
      >
        <Tabs value={tabNumber} onChange={handleTabChange} centered>
          <Tab
            sx={tabText}
            label="Authentication"
            disableFocusRipple
            disableRipple
          />
          <Tab
            sx={tabText}
            label="DB Connection"
            disableFocusRipple
            disableRipple
            disabled={disabledTabs.dbTab}
          />
          <Tab
            sx={tabText}
            label="Settings"
            disableFocusRipple
            disableRipple
            disabled={disabledTabs.settingsTab}
          />
          <Tab
            sx={tabText}
            label="Backup / Restore Data"
            disableFocusRipple
            disableRipple
            disabled={disabledTabs.backupData}
          />
          <Tab
            sx={tabText}
            label="Historical Data"
            disableFocusRipple
            disableRipple
            disabled={disabledTabs.historical}
          />
        </Tabs>
      </Box>
      {/* Authentication */}
      {tabNumber === 0 && (
        <Overview
          handleRevokeZoho={handleRevokeZoho}
          revokeLoadingZoho={revokeLoadingZoho}
          authLoadingZoho={authLoadingZoho}
          zohoAuth={zohoAuth}
          handleAuthenticateZoho={handleAuthenticateZoho}
        />
      )}
      {tabNumber === 1 && (
        <DBConnection
          setTabNumber={setTabNumber}
          setDisabledTabs={setDisabledTabs}
          orgId={orgId}
          apiKey={apiKey}
          handleConnectionUrl={(url) => {
            setConnectionUrl(url);
          }}
        />
      )}
      {tabNumber === 2 && (
        <Settings
          orgId={orgId}
          apiKey={apiKey}
          connectionUrl={connectionUrl}
          dataCenterUrl={dataCenterUrl}
          zapiKey={zapiKey}
        />
      )}
      {tabNumber === 3 && (
        <DataBackup
          orgId={orgId}
          apiKey={apiKey}
          dataCenterUrl={dataCenterUrl}
          zapiKey={zapiKey}
          connectionUrl={connectionUrl}
        />
      )}
      {tabNumber === 4 && (
        <HistoricalData
          orgId={orgId}
          zohoApiKey={apiKey}
          dataCenterUrl={dataCenterUrl}
          zapiKey={zapiKey}
        />
      )}
    </>
  );
}
