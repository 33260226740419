import {
  Box,
  Button,
  Typography,
  Container,
  Backdrop,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

import CustomDialog from "./CustomDialog";
import CustomSnackbar from "./CustomSnackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const ZOHO = window.ZOHO;

const fetchModuleFields = async (moduleNameApiName) => {
  try {
    const moduleFieldsResp = await ZOHO.CRM.META.getFields({
      Entity: moduleNameApiName,
    });

    const fileteredFields = moduleFieldsResp.fields.flatMap((field) => {
      if (
        field.data_type === "subform" ||
        field.data_type === "multiselectlookup"
      ) {
        return [];
      }
      return [
        {
          api_name: field.api_name,
          field_label: field.field_label,
          data_type: field.data_type,
        },
      ];
    });

    return {
      error: null,
      data: fileteredFields,
    };
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};

const Settings = ({ orgId, apiKey, connectionUrl, dataCenterUrl, zapiKey }) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [fetchedModules, setFetchedModules] = useState({});
  const [selectedModule, setSelectedModule] = useState(null);
  const [currentModuleFields, setCurrentModuleFields] = useState([]);
  const [currentDatabaseFields, setCurrentDatabaseFields] = useState([]);
  const [syncDuration, setSyncDuration] = useState(null);
  const [deleteBackupData, setDeleteBackupData] = useState(false);
  const [modules, setModules] = useState(null);
  const [moduleDetails, setModuleDetails] = useState("");
  const [modulesToBeSaved, setModulesToBeSaved] = useState({});
  const [pageMode, setPageMode] = useState("");
  const [showBackdrop, setShowBackDrop] = useState(false);

  const [deleteSettingId, setDeleteSettingId] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (id) => {
    setDeleteSettingId(id);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setDeleteSettingId();
    setOpenModal(false);
  };

  //snackbar state and functions
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(""); // success or error

  const syncDurations = [
    { label: "10 Mins", total_in_minutes: 10 },
    { label: "15 Mins", total_in_minutes: 15 },
    { label: "20 Mins", total_in_minutes: 20 },
    { label: "30 Mins", total_in_minutes: 30 },
    { label: "60 Mins", total_in_minutes: 60 },
    { label: "2 Hours", total_in_minutes: 120 },
    { label: "3 Hours", total_in_minutes: 180 },
    { label: "4 Hours", total_in_minutes: 240 },
    { label: "6 Hours", total_in_minutes: 360 },
    { label: "12 Hours", total_in_minutes: 720 },
    { label: "24 Hours", total_in_minutes: 1440 },
  ];

  useEffect(() => {
    if (!selectedModule) return;
    //if it exists in the fetchedModules then grab info from there
    if (fetchedModules[selectedModule.api_name]) {
      if (pageMode === "addNewMode") {
        setCurrentModuleFields(fetchedModules[selectedModule.api_name]);
      }
      return;
    }

    (async () => {
      try {
        const resposeFetchModuleFields = await fetchModuleFields(
          selectedModule.api_name
        );

        if (resposeFetchModuleFields.data) {
          if (pageMode === "addNewMode") {
            setCurrentModuleFields(resposeFetchModuleFields.data);
          }
          setFetchedModules((prev) => ({
            ...prev,
            [selectedModule.api_name]: resposeFetchModuleFields.data,
          }));

          return;
        }
      } catch (err) {
        setMessage(JSON.stringify(err));
        setSeverity("error");
        handleClickSnack();
      } finally {
      }
    })();
  }, [selectedModule]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const getAllModulesData = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
          method: "GET",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });

        if (getAllModulesData?.data?.data) {
          const moduleNeedToSave = {};
          getAllModulesData?.data?.data.forEach((moduleInfo) => {
            if (moduleInfo.module_name !== "ConnectionSchema") {
              moduleNeedToSave[moduleInfo.module_name] = {
                ...JSON.parse(moduleInfo.setting_schema),
                setting_id: moduleInfo.setting_id,
              };
            }
          });

          setModulesToBeSaved(moduleNeedToSave);
        }
      } catch (err) {
        setMessage(JSON.stringify(err));
        setSeverity("error");
        handleClickSnack();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const modulesResp = await ZOHO.CRM.META.getModules();

      const reducedModules = modulesResp.modules?.reduce((result, module) => {
        if (
          module.visible &&
          module.editable &&
          !module.parent_module?.id &&
          module.global_search_supported &&
          module.generated_type !== "linking"
        ) {
          result.push(module.api_name);
        }
        return result;
      }, []);

      setModules(
        modulesResp.modules?.reduce((result, module) => {
          if (
            module.visible &&
            module.editable &&
            !module.parent_module?.id &&
            module.global_search_supported &&
            module.generated_type !== "linking"
          ) {
            result.push(module);
          }
          return result;
        }, [])
      );
    };
    try {
      setLoading(true);
      fetchData();
    } catch (err) {
      setMessage(JSON.stringify(err));
      setSeverity("error");
      handleClickSnack();
    } finally {
      setLoading(false);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSyncDuration("");
    setSelectedModule(null);
    setCurrentModuleFields([]);
    setCurrentDatabaseFields([]);
  };

  const handleSave = async () => {
    setShowBackDrop(true);
    if (pageMode === "addNewMode") {
      //save settings to our db
      const saveModuleToDb = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",
        data: {
          moduleApiName: selectedModule.api_name,
          settingSchema: {
            moduleInfo: {
              api_name: selectedModule.api_name,
              module_name: selectedModule.plural_label,
            },
            moduleDetails: moduleDetails,
            database_fields: currentDatabaseFields,
            syncDuration: syncDuration,
            deleteBackupData: deleteBackupData,
          },
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //sync table to mysql db
      const addTable = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/setting/${selectedModule.api_name}`,
        method: "POST",
        data: {},
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          connectionUrl,
        },
      });

      if (addTable.data.error) {
        setMessage(addTable.data.error);
        setSeverity("error");
        handleClickSnack();
        setShowBackDrop(false);
        const setting_id = saveModuleToDb.data.data.setting_id;
        // Delete Module data from db (our database)
        const deleteData = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${setting_id}`,
          method: "DELETE",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });

        return;
      }

      const getCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //find cron for that module
      const findCronModule = getCron.data.data.find(
        (module) => module.req_body.moduleName === selectedModule.api_name
      );

      if (findCronModule) {
        //delete cron for that module
        const deleteCron = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs/${findCronModule.setting_id}`,
          method: "DELETE",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });
      }

      //create cron
      const createCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "POST",
        data: {
          reqUrl:
            dataCenterUrl +
            "/crm/v2/functions/easymysqlsync__syncmoduledata/actions/execute?auth_type=apikey&zapikey=" +
            zapiKey,
          reqHeaders: {},
          reqParams: {},
          reqBody: {
            moduleName: selectedModule.api_name, // Module Name
            pageNo: 0, // Always 0
          },
          reqType: "GET",
          executeIn: syncDuration.total_in_minutes, // Sync selected in the Settings in Mins
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      setModulesToBeSaved((prev) => ({
        ...prev,
        [selectedModule.api_name]: {
          moduleInfo: {
            api_name: selectedModule.api_name,
            module_name: selectedModule.plural_label,
          },
          moduleDetails: moduleDetails,
          setting_id: saveModuleToDb.data.data.setting_id,
          database_fields: currentDatabaseFields,
          syncDuration: syncDuration,
          deleteBackupData: deleteBackupData,
        },
      }));
    } else {
      //get setting id
      //filter data based on settingType=ConnectionSchema
      const response = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings?moduleApiName=${selectedModule.api_name}`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });
      const settingId = response.data.data[0].setting_id;

      if (!settingId) {
        setMessage("Setting id not found");
        setSeverity("error");
        handleClickSnack();
        setShowBackDrop(false);
        return;
      }

      // edit settings to db
      const updateModuleData = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "POST",
        data: {
          moduleApiName: selectedModule.api_name,
          settingId,
          settingSchema: {
            moduleInfo: {
              api_name: selectedModule.api_name,
              module_name: selectedModule.module_name,
            },
            moduleDetails: moduleDetails,
            database_fields: currentDatabaseFields,
            syncDuration: syncDuration,
            deleteBackupData: deleteBackupData,
          },
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //sync table to mysql db
      const editTable = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/setting/${selectedModule.api_name}`,
        method: "POST",
        data: {},
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          connectionUrl,
        },
      });

      if (editTable.data.error) {
        setMessage(editTable.data.error);
        setSeverity("error");
        handleClickSnack();
        setShowBackDrop(false);
        return;
      }

      //get crons for all modules
      const getCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEY
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      //find cron for that module
      const findCronModule = getCron.data.data.find(
        (module) => module.req_body.moduleName === selectedModule.api_name
      );

      //update cron info for that module
      const updateCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "PUT",
        data: {
          settingId: findCronModule.setting_id,
          executeIn: syncDuration.total_in_minutes, // Sync selected in the Settings in Mins
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      setModulesToBeSaved((prev) => ({
        ...prev,
        [selectedModule.api_name]: {
          moduleInfo: {
            api_name: selectedModule.api_name,
            module_name: selectedModule.module_name,
          },
          moduleDetails: moduleDetails,
          database_fields: currentDatabaseFields,
          syncDuration: syncDuration,
          deleteBackupData: deleteBackupData,
          setting_id: settingId,
        },
      }));
    }
    setOpen(false);
    setSelectedModule(null);
    setCurrentModuleFields([]);
    setCurrentDatabaseFields([]);
    setSyncDuration(null);
    setDeleteBackupData(false);
    setPageMode("");

    setShowBackDrop(false);
  };
  const handleClickSnack = () => {
    setOpenSnack(true);
  };
  //snackbar
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleDeleteSetting = async (module) => {
    try {
      setDeleteLoading(true);

      // Delete Module data from db (our database)
      const deleteData = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings/${module.setting_id}`,
        method: "DELETE",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      if (deleteData.data.error) {
        setMessage(deleteData.data.error);
        setSeverity("error");
        handleClickSnack();
        setShowBackDrop(false);
        return;
      }

      //delete table from mysql
      const deleteTable = await axios.request({
        url: `${process.env.REACT_APP_DBURL}/setting/${module?.moduleInfo?.api_name}`,
        method: "DELETE",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          connectionUrl,
        },
      });

      //get crons for all modules
      const getCron = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      // //find cron for that module
      // const findCronModule = getCron.data.data.find(
      //   (cron) => cron.req_body.moduleName === module.moduleInfo.api_name
      // );

      //filter cron for that module
      const filterCronModule = getCron.data.data.filter(
        (cron) => cron.req_body.moduleName === module.moduleInfo.api_name
      );

      //delete cron for that module
      for (let item of filterCronModule) {
        const deleteCron = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs/${item.setting_id}`,
          method: "DELETE",
          headers: {
            orgid: orgId, // Org ID
            apikey: apiKey, // API KEy
            connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
          },
        });
      }

      const modulesNeedToBeSaved = {};

      Object.values(modulesToBeSaved).forEach((moduleInfo) => {
        if (moduleInfo.setting_id !== module.setting_id) {
          modulesNeedToBeSaved[moduleInfo.moduleInfo.module_name] = moduleInfo;
        }
      });

      setModulesToBeSaved(modulesNeedToBeSaved);

      handleCloseModal();
    } catch (err) {
      setMessage("Connection Url is wrong!");
      setSeverity("error");
      handleClickSnack();
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleModuleChange = (event, value) => {
    setSelectedModule(value);
    setCurrentDatabaseFields([]);
  };

  if (loading)
    return (
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Container maxWidth="xl" sx={{ py: 5 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomSnackbar
        open={openSnack}
        handleClose={handleCloseSnack}
        message={message}
        severity={severity}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "2rem",
        }}
      >
        {/* <button
          onClick={async () => {
            //get crons for all modules
            const getCron = await axios.request({
              url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
              method: "GET",
              headers: {
                orgid: orgId, // Org ID
                apikey: apiKey, // API KEy
                connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
              },
            });

            
          }}
        >
          Get Cron
        </button> */}

        <Typography sx={{ fontSize: "24px" }}>Modules</Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#B27310",
            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
            "&:hover": {
              backgroundColor: "#B27310",
            },
            p: "8px 22px",
          }}
          onClick={() => {
            setPageMode("addNewMode");
            setSelectedModule(null);
            setSyncDuration({
              label: "60 Mins",
              total_in_minutes: 60,
            });
            setDeleteBackupData(true);
            setCurrentDatabaseFields(null);
            setCurrentModuleFields(null);
            handleClickOpen();
          }}
        >
          + Add New
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "row",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        {Object.values(modulesToBeSaved)?.map((module) => {
          return (
            <Box
              key={module?.moduleInfo.module_name}
              p={2}
              sx={{
                width: "17.125rem",
                borderRadius: "4px",
                borderTop: "2px solid #759BB3",
                boxShadow:
                  "0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)",
                "&:hover .button-box": {
                  visibility: "visible",
                },
              }}
            >
              <Typography sx={{ fontSize: "24px" }}>
                {module?.moduleInfo?.module_name}
              </Typography>

              {module.moduleDetails && (
                <Typography sx={{ fontSize: "14px" }}>
                  {module.moduleDetails}
                </Typography>
              )}

              <Box
                className="button-box"
                sx={{
                  transition: "visibility 0.2s ease-in-out",
                }}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    "& .MuiButtonBase-root": {
                      p: 0,
                    },
                    mr: 1,
                  }}
                  onClick={async () => {
                    setShowBackDrop(true);
                    const selectedFields = {};
                    const moduleFieldsAgainstApiKey = {};

                    const responseFetchModuleFields = await fetchModuleFields(
                      module?.moduleInfo.api_name
                    );
                    module?.database_fields.forEach(
                      (field) =>
                        (selectedFields[
                          field.api_name + "__" + field.data_type
                        ] = field)
                    );

                    // if something went wrong
                    if (responseFetchModuleFields.error) {
                      //show error
                      return;
                    }

                    const filteredModuleFields = [];

                    responseFetchModuleFields.data.flatMap((field) => {
                      //push all those fields to filteredModuleFields that are not selected
                      if (
                        !selectedFields[field.api_name + "__" + field.data_type]
                      ) {
                        filteredModuleFields.push({
                          api_name: field.api_name,
                          field_label: field.field_label,
                          data_type: field.data_type,
                          field_exist_in_zoho: true,
                        });
                      }

                      //add field to moduleFieldsAgainstApiKey
                      moduleFieldsAgainstApiKey[
                        field.api_name + "__" + field.data_type
                      ] = {
                        api_name: field.api_name,
                        field_label: field.field_label,
                        data_type: field.data_type,
                      };
                    });

                    const filteredCurrentDatabaseFields =
                      module.database_fields.map((field, index) => {
                        // if field exists in the moduleFieldsAgainstApiKey that means this field exist in that module i mean exist in the zoho
                        // else we will add a extra parameter to track the field
                        if (
                          moduleFieldsAgainstApiKey[
                            field.api_name + "__" + field.data_type
                          ]
                        ) {
                          return { ...field, field_exist_in_zoho: true };
                        } else {
                          return { ...field, field_exist_in_zoho: false };
                        }
                      });

                    setPageMode("editMode");

                    setTimeout(() => {
                      setFetchedModules((prev) => ({
                        ...prev,
                        [selectedModule]: responseFetchModuleFields.data,
                      }));
                      setSelectedModule(module?.moduleInfo);
                      setSyncDuration(module?.syncDuration);
                      setDeleteBackupData(module?.deleteBackupData);

                      setCurrentDatabaseFields(filteredCurrentDatabaseFields);
                      setCurrentModuleFields(filteredModuleFields);
                      handleClickOpen();
                      setShowBackDrop(false);
                    }, 100);
                  }}
                >
                  Edit
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={async () => handleOpenModal(module)}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "white",
              width: 500,
            }}
          >
            {" "}
            <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: 16 }}>
              The related workflow won't work anymore if you delete this
              settings. Do you wish to proceed?
            </Typography>
            <Box
              sx={{
                mt: 3,
                ml: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <Button
                sx={{ width: 120 }}
                variant="outlined"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                disabled={deleteLoading}
                sx={{ width: 120 }}
                variant="contained"
                color="error"
                onClick={() => handleDeleteSetting(deleteSettingId)}
              >
                {deleteLoading ? (
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
                ) : (
                  "Proceed"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* dialog */}

      {pageMode === "editMode" ? (
        <CustomDialog
          selectedModule={selectedModule}
          mode={pageMode}
          open={open}
          handleClose={handleClose}
          modules={modules.filter(
            (module) => !modulesToBeSaved[module.api_name]
          )}
          handleModuleChange={handleModuleChange}
          currentModuleFields={currentModuleFields}
          setCurrentModuleFields={setCurrentModuleFields}
          currentDatabaseFields={currentDatabaseFields}
          setCurrentDatabaseFields={setCurrentDatabaseFields}
          syncDuration={syncDuration}
          setSyncDuration={setSyncDuration}
          deleteBackupData={deleteBackupData}
          setDeleteBackupData={setDeleteBackupData}
          syncDurations={syncDurations}
          handleSave={handleSave}
          syncAllFields={() => {
            setCurrentDatabaseFields(fetchedModules[selectedModule.api_name]);
            setTimeout(() => {
              setCurrentModuleFields([]);
            }, 100);
          }}
          unSelectAllFields={() => {
            setCurrentModuleFields(fetchedModules[selectedModule.api_name]);
            setCurrentDatabaseFields([]);
          }}
          handleDeleteField={(value) => {
            const filteredCurrentDatabaseFields = currentDatabaseFields.filter(
              (field) => field.api_name !== value.api_name
            );

            setCurrentDatabaseFields([...filteredCurrentDatabaseFields]);
          }}
        />
      ) : (
        <CustomDialog
          open={open}
          mode={pageMode}
          selectedModule={selectedModule}
          handleClose={handleClose}
          modules={modules?.filter(
            (module) => !modulesToBeSaved[module.api_name]
          )}
          handleModuleChange={handleModuleChange}
          currentModuleFields={currentModuleFields}
          syncAllFields={() => {
            setCurrentDatabaseFields([
              ...fetchedModules[selectedModule.api_name],
            ]);
            setTimeout(() => {
              setCurrentModuleFields([]);
            }, 100);
          }}
          unSelectAllFields={() => {
            setCurrentModuleFields([
              ...fetchedModules[selectedModule.api_name],
            ]);

            setCurrentDatabaseFields([]);
          }}
          syncDurations={syncDurations}
          setCurrentModuleFields={setCurrentModuleFields}
          currentDatabaseFields={currentDatabaseFields}
          setCurrentDatabaseFields={setCurrentDatabaseFields}
          syncDuration={syncDuration}
          setSyncDuration={setSyncDuration}
          deleteBackupData={deleteBackupData}
          setDeleteBackupData={setDeleteBackupData}
          handleSave={handleSave}
        />
      )}
    </Container>
  );
};

export default Settings;
