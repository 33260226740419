import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Backdrop,
  Modal,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import DateTimePicker from "react-datetime-picker";
import Papa from "papaparse";
import { format, subMonths } from "date-fns";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from "axios";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-clock/dist/Clock.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";

import CustomSnackbar from "./CustomSnackbar";
import { CircleNotifications } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const DataBackup = ({
  orgId,
  apiKey,
  connectionUrl,
  dataCenterUrl,
  zapiKey,
}) => {
  const [modules, setModules] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [time, setTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [loading_download_all, set_loading_download_all] = useState(false);
  const [total_records, set_total_records] = useState(null);
  const [total_pagination, set_total_pagination] = useState(null);
  const [chunks_of_records, set_chunks_of_records] = useState(null);
  //snackbar state and functions
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(""); // success or error
  const [prevRunningSetting, setPrevRunningSetting] = useState();
  const record_limit = 20000;

  const [handleSubmitLoading, setHandleSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    (async () => {
      const getAllModulesData = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });

      if (getAllModulesData?.data?.data) {
        const modules = [];
        getAllModulesData?.data?.data.forEach((moduleInfo) => {
          if (moduleInfo.module_name !== "ConnectionSchema") {
            modules.push(JSON.parse(moduleInfo.setting_schema).moduleInfo);
          }
        });
        setModules(modules);
      }

      const resp = await axios.request({
        url: `https://api.easy-pluginz.com/admin/v2/utils/cronjobs`,
        method: "GET",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: "easymysqlsync", // Conn Name
        },
      });
      let result = resp?.data?.data || [];

      let findCron = result?.find(
        (item) =>
          item?.setting_type === "CronSetting" &&
          item?.status === "In Progress" &&
          item?.repeat_at_min === 1 &&
          !item?.req_body?.cv_id
      );

      if (findCron) {
        setPrevRunningSetting(findCron);
      }
    })();
  }, []);

  const downloadCSV = (args) => {
    let filename = args.filename || "export.csv";
    let columns = args.columns || null;

    let csv = Papa.unparse({ data: args.data, fields: columns });
    if (csv == null) return;

    var blob = new Blob([csv]);
    if (window.navigator.msSaveOrOpenBlob)
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, args.filename);
    else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = filename;
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }
    setLoading(false);
  };
  const handleModuleChange = (event, value) => {
    setSelectedModule(value);
    set_total_records(null);
    set_chunks_of_records(null);
  };

  const handleCronCreate = async () => {
    try {
      const resp = await axios.request({
        url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/utils/cronjobs`,
        method: "POST",
        data: {
          reqUrl:
            dataCenterUrl +
            "/crm/v2/functions/easymysqlsync__restoremoduledata/actions/execute?auth_type=apikey&zapikey=" +
            zapiKey,
          reqHeaders: {},
          reqParams: {},
          reqBody: {
            moduleName: selectedModule?.api_name,
            timeRange: dayjs(time).format("YYYY-MM-DD HH:mm:ss"),
            pageNo: 1,
            perPage: 1000,
            type: "restore",
          },
          reqType: "GET",
          executeIn: 1, // Sync selected in the Settings in Mins
        },
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
      });
      if (resp?.data?.data && resp?.data?.error === null) {
        setPrevRunningSetting({ setting_id: resp?.data?.data });
        setMessage("Successfully running..");
        setSeverity("success");
        setOpenSnack(true);
      } else {
        setMessage("Something went wrong....");
        setSeverity("error");
        setOpenSnack(true);
      }
    } catch (error) {
      setMessage(error.message);
      setSeverity("error");
      setOpenSnack(true);
    }
  };

  const handleCronUpdate = async () => {
    try {
      setHandleSubmitLoading(true);
      let reqBody = {
        url: `https://api.easy-pluginz.com/admin/v2/utils/cronjobs`,
        method: "PUT",
        headers: {
          orgid: orgId, // Org ID
          apikey: apiKey, // API KEy
          connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
        },
        data: {
          settingId: prevRunningSetting?.setting_id,
          settingStatus: "Stopped",
        },
      };
      const resp = await axios.request(reqBody);

      if (
        resp.data?.data === "Setting Status/Record Count Updated" &&
        resp.data?.error === null
      ) {
        setPrevRunningSetting();
        setHandleSubmitLoading(false);
        setMessage("Successfully Stopped");
        setSeverity("success");
        setOpenSnack(true);
        handleCloseModal();
      } else {
        setHandleSubmitLoading(false);
        setMessage("Something went wrong. Try again later...!");
        setSeverity("error");
        setOpenSnack(true);
      }
    } catch (error) {
      setMessage(error.message);
      setSeverity("error");
      setOpenSnack(true);
      setHandleSubmitLoading(false);
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <CustomSnackbar
        open={openSnack}
        handleClose={handleCloseSnack}
        message={message}
        severity={severity}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6} lg={4.5}>
          <Typography variant="h5" mb={3}>
            Backup / Restore Data
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Typography>Select Module</Typography>
              <Autocomplete
                id="modules-select"
                sx={{ width: "100%", maxWidth: 276 }}
                size="small"
                options={modules || []}
                autoHighlight
                getOptionLabel={(option) => option.module_name}
                onChange={handleModuleChange}
                value={selectedModule}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    placeholder="Select Module"
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
              }}
              mb={4}
            >
              <Typography>Date</Typography>

              <DateTimePicker
                onChange={(value) => {
                  set_total_records(null);
                  set_chunks_of_records(null);
                  setTime(value);
                }}
                value={time}
                minDate={subMonths(new Date(), 1)}
                maxDate={new Date()}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <LoadingButton
                variant="contained"
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#B27310",
                  border:
                    selectedModule && time
                      ? "1px solid #B27310"
                      : "1px solid #0000001f",
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                  "&:hover": {
                    backgroundColor: "#B27310",
                  },
                }}
                disabled={!selectedModule || !time}
                onClick={async () => {
                  try {
                    setLoading(true);
                    const count_total_record = await axios.request({
                      url: `${process.env.REACT_APP_DBURL}/count_total_record`,
                      method: "POST",
                      data: {
                        timeRange: format(time, "yyyy-MM-dd HH:mm:ss"),
                        moduleName: selectedModule.api_name,
                      },
                      headers: {
                        orgid: orgId, // Org ID
                        apikey: apiKey, // API KEy
                        connname: process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                        connectionUrl,
                      },
                    });

                    set_total_records(
                      Number(count_total_record.data.data[0].total_records)
                    );
                    const total_pagination = Math.ceil(
                      count_total_record.data.data[0].total_records /
                        record_limit
                    );

                    const chunksOfRecords = {};
                    new Array(total_pagination)
                      .fill(null)
                      .forEach((_, index) => {
                        let limit;
                        let offset = index * record_limit;

                        if (
                          index * record_limit + record_limit >
                          count_total_record.data.data[0].total_records
                        ) {
                          limit =
                            count_total_record.data.data[0].total_records -
                            index * record_limit;
                        } else {
                          limit = record_limit;
                        }
                        const apiRequest = {
                          url: `${process.env.REACT_APP_DBURL}/grab_records_based_on_time`,
                          method: "POST",
                          data: {
                            timeRange: format(time, "yyyy-MM-dd HH:mm:ss"),
                            moduleName: selectedModule.api_name,
                            limit,
                            offset,
                          },
                          headers: {
                            orgid: orgId, // Org ID
                            apikey: apiKey, // API KEy
                            connname:
                              process.env.REACT_APP_EXTENSION_IDENTIFIER, // Conn Name
                            connectionUrl,
                          },
                        };
                        chunksOfRecords[index] = {
                          id: index,
                          loading: false,
                          apiRequest,
                        };
                      });
                    set_chunks_of_records(chunksOfRecords);

                    set_total_pagination(total_pagination);
                  } catch (err) {
                    setMessage(err);
                    setSeverity("error");
                    handleClickSnack();
                  } finally {
                    setLoading(false);
                  }
                }}
                size="small"
              >
                Fetch Records Information
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={7.5}>
          {(() => {
            if (total_records === 0)
              return (
                <Typography variant="h5" mb={3}>
                  No Records Found
                </Typography>
              );
            return (
              total_records &&
              total_records > 0 && (
                <>
                  <Typography variant="h5" mb={3}>
                    Total Records Found: {total_records}
                  </Typography>
                  <Box
                    sx={{
                      border: "1px solid #0000001F",
                      borderRadius: "8px",
                      p: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    mb={2}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Restore data in CRM
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#00000099" }}>
                        Nisl vivamus at vel commodo leo risus sit. Dignissim ac
                        vestibulum.
                      </Typography>
                    </Box>
                    {prevRunningSetting?.setting_id ? (
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                          border:
                            selectedModule && time
                              ? "1px solid #B27310"
                              : "1px solid #0000001f",
                          "&:hover": {
                            backgroundColor: "rgba(255, 165, 24, 0.04)",
                            border: "1px solid #B27310",
                          },
                          color: "#B27310",
                          textTransform: "capitalize",
                        }}
                        // disabled={!selectedModule || !time}
                        onClick={handleOpenModal}
                        size="small"
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                          border:
                            selectedModule && time
                              ? "1px solid #B27310"
                              : "1px solid #0000001f",
                          "&:hover": {
                            backgroundColor: "rgba(255, 165, 24, 0.04)",
                            border: "1px solid #B27310",
                          },
                          color: "#B27310",
                          textTransform: "capitalize",
                        }}
                        disabled={!selectedModule || !time}
                        onClick={handleCronCreate}
                        size="small"
                      >
                        Restore Data
                      </Button>
                    )}
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #0000001F",
                      borderRadius: "8px",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      mb={2}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        Download in CSV
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={loading_download_all}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                          border:
                            selectedModule && time
                              ? "1px solid #B27310"
                              : "1px solid #0000001f",
                          "&:hover": {
                            backgroundColor: "rgba(255, 165, 24, 0.04)",
                            border: "1px solid #B27310",
                          },
                          color: "#B27310",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          set_loading_download_all(true);
                          Promise.all(
                            Object.values(chunks_of_records).map(
                              async (record, index) => {
                                set_chunks_of_records((prev) => ({
                                  ...prev,
                                  [index]: { ...prev[index], loading: true },
                                }));
                                const fetch_records = await axios.request(
                                  record.apiRequest
                                );

                                const csvData = fetch_records.data.data.map(
                                  (indv) => indv.record_response
                                );

                                downloadCSV({
                                  filename: `${
                                    selectedModule.api_name
                                  }_${format(time, "yyyy-MM-dd HH:mm:ss")}_${
                                    index + 1
                                  }.csv`,
                                  data: csvData,
                                });
                                set_chunks_of_records((prev) => ({
                                  ...prev,
                                  [index]: { ...prev[index], loading: false },
                                }));
                              }
                            )
                          )
                            .then((res) => {
                              setMessage("Downloaded All Files Successfully");
                              setSeverity("success");
                              handleClickSnack();
                              set_loading_download_all(false);
                            })
                            .catch((error) => {
                              setMessage(error);
                              setSeverity("error");
                              handleClickSnack();
                              set_loading_download_all(false);
                            });
                        }}
                      >
                        Download All
                      </Button>
                    </Box>
                    <Divider />
                    <Box py={2}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Record Range
                      </Typography>
                    </Box>
                    <Divider />
                    {chunks_of_records &&
                      Object.values(chunks_of_records).map((record, index) => {
                        return (
                          <>
                            <Box
                              py={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="body2">
                                {" "}
                                {record.apiRequest.data.offset + 1} -&nbsp;
                                {record.apiRequest.data.limit +
                                  record.apiRequest.data.offset}
                              </Typography>

                              {record.loading && <CircularProgress size={25} />}
                              {!record.loading && (
                                <FileDownloadOutlinedIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={async () => {
                                    try {
                                      // setLoading(true);
                                      set_chunks_of_records((prev) => ({
                                        ...prev,
                                        [index]: {
                                          ...prev[index],
                                          loading: true,
                                        },
                                      }));
                                      const count_total_record =
                                        await axios.request(record.apiRequest);

                                      const csvData =
                                        count_total_record.data.data.map(
                                          (indv) => indv.record_response
                                        );

                                      downloadCSV({
                                        filename: `${
                                          selectedModule.api_name
                                        }_${format(
                                          time,
                                          "yyyy-MM-dd HH:mm:ss"
                                        )}_${index + 1}.csv`,
                                        data: csvData,
                                      });
                                    } catch (err) {
                                    } finally {
                                      set_chunks_of_records((prev) => ({
                                        ...prev,
                                        [index]: {
                                          ...prev[index],
                                          loading: false,
                                        },
                                      }));
                                    }
                                  }}
                                />
                              )}
                            </Box>
                            {Object.values(chunks_of_records).length - 1 !==
                              index && <Divider />}
                          </>
                        );
                      })}
                  </Box>
                </>
              )
            );
          })()}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "white",
              width: 500,
            }}
          >
            {" "}
            <Typography sx={{ mt: 4, fontWeight: "bold", fontSize: 16 }}>
              Do you want to stop this?
            </Typography>
            <Box
              sx={{
                mt: 3,
                ml: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <Button
                sx={{ width: 120 }}
                variant="outlined"
                onClick={handleCloseModal}
              >
                No
              </Button>

              <Button
                disabled={handleSubmitLoading}
                sx={{ width: 120 }}
                variant="contained"
                color="error"
                onClick={() => handleCronUpdate(prevRunningSetting?.setting_id)}
              >
                {handleSubmitLoading ? (
                  <CircularProgress sx={{ color: "white", ml: 1 }} size={16} />
                ) : (
                  "Yes"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default DataBackup;
